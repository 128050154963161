"use client";
import Markdown from "@/utils/Markdown";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";

export default function FooterComponent(props: any) {
  const { footerResponse } = props;
  const [showTerms, setTerms] = useState(false);
  const [showPrivacy, setPrivacy] = useState(false);
  const [isCookiesVisible, setIsCookiesVisible] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const handleAccept = () => {
    setIsCookiesVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setTerms(false);
        setPrivacy(false);
      }
    };
    if (showTerms || showPrivacy) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showTerms, showPrivacy, popupRef]);
  // const data = {
  //   currentYear: new Date().getFullYear(),
  //   // footerPages: [
  //   //   {
  //   //     title: "Company",
  //   //     links: [
  //   //       {
  //   //         name: "About Us",
  //   //         url: "/about",
  //   //       },
  //   //       {
  //   //         name: "Our Latest News",
  //   //         url: "/news",
  //   //       },
  //   //       {
  //   //         name: "Our Blog",
  //   //         url: "/agents",
  //   //       },
  //   //       {
  //   //         name: "Become an Agent",
  //   //         url: "/join-our-team",
  //   //       },
  //   //       {
  //   //         name: "Join our team",
  //   //         url: "/join-our-team",
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     title: "Personal",
  //   //     links: [
  //   //       {
  //   //         name: "About Us",
  //   //         url: "/about",
  //   //       },
  //   //       {
  //   //         name: "Our Latest News",
  //   //         url: "/news",
  //   //       },
  //   //       {
  //   //         name: "Our Blog",
  //   //         url: "/agents",
  //   //       },
  //   //       {
  //   //         name: "Become an Agent",
  //   //         url: "/join-our-team",
  //   //       },
  //   //       {
  //   //         name: "Join our team",
  //   //         url: "/join-our-team",
  //   //       },
  //   //       {
  //   //         name: "Join our team",
  //   //         url: "/join-our-team",
  //   //       },
  //   //       {
  //   //         name: "Join our team",
  //   //         url: "/join-our-team",
  //   //       },
  //   //       {
  //   //         name: "Join our team",
  //   //         url: "/join-our-team",
  //   //       },
  //   //       {
  //   //         name: "Join our team",
  //   //         url: "/join-our-team",
  //   //       },
  //   //       {
  //   //         name: "Join our team",
  //   //         url: "/join-our-team",
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     title: "Business",
  //   //     links: [
  //   //       {
  //   //         name: "About Us",
  //   //         url: "/about",
  //   //       },
  //   //       {
  //   //         name: "Our Latest News",
  //   //         url: "/news",
  //   //       },
  //   //       {
  //   //         name: "Our Blog",
  //   //         url: "/agents",
  //   //       },
  //   //       {
  //   //         name: "Become an Agent",
  //   //         url: "/join-our-team",
  //   //       },
  //   //       {
  //   //         name: "Join our team",
  //   //         url: "/join-our-team",
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     title: "Support",
  //   //     links: [
  //   //       {
  //   //         name: "FAQs",
  //   //         url: "/faq",
  //   //       },
  //   //       {
  //   //         name: "Contact us",
  //   //         url: "/contact-us",
  //   //       },
  //   //       {
  //   //         name: "Fraud awareness",
  //   //         url: "/fraud-awareness",
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     title: "Legal",
  //   //     links: [
  //   //       {
  //   //         name: "Terms & Conditions",
  //   //       },
  //   //       {
  //   //         name: "Privacy Policy",
  //   //         url: "",
  //   //       },
  //   //       {
  //   //         name: "Cookie Policy",
  //   //         url: "",
  //   //       },
  //   //     ],
  //   //   },
  //   // ],
  // };

  return (
    <>
      <div
        id="overlay"
        className={`${showTerms || showPrivacy ? "overlay z-[100]" : "hidden"}`}
      ></div>
      <footer className="relative pb-6 bg-black pt-8">
        <div className="footer-container">
          <div className="w-full border-[1px] border-b-white/[.8] border-t-transparent border-l-transparent border-r-transparent -mt-[7rem]">
            <div className="md:grid grid-cols-8 flex max-md:hidden">
              <div className="logo mt-[9rem]">
                <Image
                  className="block"
                  src="/assets/images/footer/cu-pay.png"
                  alt="desktop weepay logo"
                  width={200}
                  height={100}
                />
              </div>

              <div className="flex col-start-3 col-span-2 mt-[150px]">
                <div className="cursor-pointer">
                  {" "}
                  <Link
                    target="_blank"
                    href={`${footerResponse?.socialMedia?.googlePlayLink}`}
                  >
                    <Image
                      className="block"
                      src="/assets/images/shared/googlestore.png"
                      alt="desktop weepay logo"
                      width={200}
                      height={100}
                    />
                  </Link>
                </div>

                <div className="cursor-pointer ml-6">
                  {" "}
                  <Link
                    target="_blank"
                    href={`${footerResponse?.socialMedia?.appStoreLink}`}
                  >
                    <Image
                      className="block"
                      src="/assets/images/shared/applestore.png"
                      alt="desktop weepay logo"
                      width={200}
                      height={100}
                    />
                  </Link>
                </div>
              </div>

              <div className="col-span-3 lg:mb-0 ml-[10rem]">
                <Image
                  className="block"
                  src="/assets/images/footer/phone.png"
                  alt="desktop weepay logo"
                  width={400}
                  height={100}
                />
              </div>

              <div className="flex items-center justify-end mt-[5rem]">
                <Image
                  className=" lg:block"
                  src="/assets/images/footer/QR.png"
                  alt="desktop weepay logo"
                  width={100}
                  height={100}
                />
              </div>
            </div>

            <div className="flex md:hidden">
              <div className="flex flex-col justify-evenly">
                <div className="logo mt-[6rem]">
                  <Image
                    className="block"
                    src="/assets/images/footer/cu-pay.png"
                    alt="desktop weepay logo"
                    width={300}
                    height={100}
                  />
                </div>

                <div className="flex col-start-3 col-span-2 gap-[5px]">
                  <div className="cursor-pointer">
                    {" "}
                    <Link
                      target="_blank"
                      href={`${footerResponse?.socialMedia?.googlePlayLink}`}
                    >
                      <Image
                        className="block"
                        src="/assets/images/footer/googleStoreMobile.png"
                        alt="desktop weepay logo"
                        width={50}
                        height={100}
                      />
                    </Link>
                  </div>
                  <div className="cursor-pointer">
                    {" "}
                    <Link
                      target="_blank"
                      href={`${footerResponse?.socialMedia?.appStoreLink}`}
                    >
                      <Image
                        className="block"
                        src="/assets/images/footer/appleStoreMobile.png"
                        alt="desktop weepay logo"
                        width={50}
                        height={100}
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-[2rem]">
                <div className="col-span-3 lg:mb-0">
                  <Image
                    className="block"
                    src="/assets/images/footer/phone.png"
                    alt="desktop weepay logo"
                    width={800}
                    height={100}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-6 md:grid-cols-6  pt-8 gap-3">
            <div className="flex flex-col items-start col-span-3 md:col-span-1 mb-[2rem] md:mb-0 ">
              <Link target="_blank" href="/" className="hover:border-b-[0]">
                <Image
                  src="/assets/images/footer/cu-logo.png"
                  alt="cash united logo"
                  width={154}
                  height={40}
                />
              </Link>

              <div className="flex gap-x-4 pt-8">
                <Link
                  target="_blank"
                  href={`${footerResponse?.socialMedia?.instagramLink}`}
                  className="hover:border-b-[0]"
                >
                  <Image
                    src="/assets/images/footer/Insta.png"
                    alt="instagram"
                    width={40}
                    height={30}
                  />
                </Link>

                <Link
                  target="_blank"
                  href={`${footerResponse?.socialMedia?.facebookLink}`}
                  className="hover:border-b-[0]"
                >
                  <Image
                    src="/assets/images/footer/FB.png"
                    alt="facebook"
                    width={40}
                    height={30}
                  />
                </Link>

                <Link
                  target="_blank"
                  href={`${footerResponse?.socialMedia?.linkedinLink}`}
                  className="hover:border-b-[0]"
                >
                  <Image
                    src="/assets/images/footer/linkedin.png"
                    alt="linkedin"
                    width={40}
                    height={30}
                  />
                </Link>
              </div>
            </div>

            {footerResponse?.footerLinks?.map((footer: any, index: any) => (
              <div
                className={`w-full flex flex-col items-start text-[18px] text-white col-span-3 md:col-span-1 ${index !== 0 ? "mt-6 sm:mt-0" : " "}`}
                key={index}
              >
                <span className="font-avBlack">{footer.title}</span>

                {footer.links.map((link: any, index: any) => (
                  <React.Fragment key={index}>
                    {link?.text === "Terms & Conditions" ||
                      link?.text === "Privacy Policy" ||
                      link?.text === "Cookie Policy" ? (
                      <Link
                        href=""
                        className="font-avMedium hover:text-red hover:border-b-[0] text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          if (link?.text === "Terms & Conditions") {
                            setTerms(true);
                          }
                          if (link?.text === "Privacy Policy") {
                            setPrivacy(true);
                          } else if (link?.text === "Cookie Policy") {
                            setIsCookiesVisible(true);
                          }
                        }}
                      >
                        {link?.text}
                      </Link>
                    ) : (
                      <Link
                        className="font-avMedium hover:text-red hover:border-b-[0] text-white"
                        href={link?.link || "/"}
                      >
                        {link.text}
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>

          <div className="w-full flex footer-text pt-8 text-white flex-col lg:flex-row lg:items-center justify-between max-w-[100%]">
            <span>
              Copyright © {new Date().getFullYear()} Cash United. All Rights
              Reserved | Central Bank of Lebanon Authorization # 08/30/01 dated
              26/09/2001 & 21/22/15 date 24/08/2015
            </span>
            <div className="flex items-center lg:justify-between gap-x-2 mt-8 md:mt-0">
              Designed and developed by{" "}
              <Link className="hover:border-b-[0]" href="https://klevr.com.lb/">
                <Image
                  src="/assets/images/footer/klevr-logo.png"
                  alt="klevr logo"
                  width={40}
                  height={30}
                />
              </Link>
            </div>
          </div>
        </div>

        {isCookiesVisible && (
          <div className="fixed bottom-0 left-0 right-0 bg-white text-black p-4 z-50 flex-col lg:flex-row">
            <div className="container mx-auto flex justify-between items-center">
              <p className="bdy-txt-6">
                This website uses cookies to identify your recurrent visits,
                location and preferences, to measure the effectiveness of
                campaigns and to examine traffic. <br />
                This information allows us to regularly evolve and improve our
                online services. <br /> By continuing to browse, you give us
                your consent for the use of cookies.
              </p>
              <button className="btn-primary" onClick={handleAccept}>
                I Accept
              </button>
            </div>
          </div>
        )}

        {showTerms && (
          <div
            ref={popupRef}
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80%] h-[84%] bg-white border p-8 overflow-y-auto z-[100]"
          >
            <div className="text-black">
              <p className="text-left whitespace-nowrap mb-4 mt-4 dialog-title">
                {footerResponse?.terms?.title}
              </p>
              <Markdown cls="bdy-txt-6">
                {footerResponse?.terms?.text}
              </Markdown>
            </div>

            <div className="md:top-[20px] md:mr-[20px] top-[10px] mr-[10px] right-0 absolute">
              <button
                className="md:p-[10px] p-0"
                onClick={() => setTerms(false)}
              >
                <Image
                  src="/assets/images/footer/close.svg"
                  alt="Close"
                  width={25}
                  height={20}
                />
              </button>
            </div>
          </div>
        )}

        {showPrivacy && (
          <div
            ref={popupRef}
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80%] h-[84%] bg-white border p-8 overflow-y-auto z-[100]"
          >
            <div className="text-black">
              <p className="text-left whitespace-nowrap mb-4 mt-4 dialog-title">
                {" "}
                {footerResponse?.privacy?.title}
              </p>

              <Markdown cls="bdy-txt-6">
                {footerResponse?.privacy?.text}
              </Markdown>
            </div>

            <div className="md:top-[20px] md:mr-[20px] top-[10px] mr-[10px] right-0 absolute">
              <button
                className="md:p-[10px] p-0"
                onClick={() => setPrivacy(false)}
              >
                <Image
                  src="/assets/images/footer/close.svg"
                  alt="Close"
                  width={25}
                  height={20}
                />
              </button>
            </div>
          </div>
        )}
      </footer>
    </>
  );
}
