/* eslint-disable react-hooks/rules-of-hooks */
"use client";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";

export default function HeaderComponent(props: any) {
  const { headerResponse } = props;
  const [isMobileMenuHidden, setIsMobileMenuHidden] = useState(true);
  const [routes] = useState(headerResponse?.headerLinks);
  const [storeLink, setStoreLink] = useState("");

  const [openMenuId, setOpenMenuId] = useState(null);
  const dropdownRef = useRef<HTMLUListElement | null>(null);
  const toggleMenu = (id: any) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const closeMenu = () => {
    setOpenMenuId(null);
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent || window.navigator.vendor;

    if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      typeof window !== "undefined" &&
      !("MSStream" in window)
    ) {
      setStoreLink("https://apps.apple.com/lb/app/weepay-wallet/id6444006006");
    } else if (/android/i.test(userAgent)) {
      setStoreLink(
        "https://play.google.com/store/apps/details?id=com.cu.weepay&hl=en"
      ); // Google Play Store URL for Android
    } else if (/Mac/.test(userAgent)) {
      setStoreLink("https://apps.apple.com/lb/app/weepay-wallet/id6444006006");
    } else if (/Win/.test(userAgent)) {
      setStoreLink(
        "https://play.google.com/store/apps/details?id=com.cu.weepay&hl=en"
      );
    }
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const pathname = usePathname();
  // const router = useRouter();

  useEffect(() => {
    const currentIndex = routes.findIndex(
      (route: any) => route.link === pathname
    );
    if (currentIndex !== -1) {
      setSelectedIndex(currentIndex);
    }
  }, [pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeMenu();
      }
    };

    if (openMenuId) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openMenuId]);
  return (
    <>
      <nav className="gradient-header flex items-center justify-between flex-wrap p-[1.6rem]">
        {/* <div className="traingle md:left-[22%] left-[84%] border-l-[25px] border-r-[25px] border-t-[15px]"></div> */}
        <div className="flex items-center flex-shrink-0 text-white md:ml-[1rem]">
          <Link href="/" className="hover:border-b-[0] -mt-[5px] md:mt-0">
            <Image
              src="/assets/images/header/cu-header-logo.png"
              alt="Logo"
              width={180}
              height={50}
            />
          </Link>
        </div>
        <div className="block lg:hidden cursor-pointer">
          {isMobileMenuHidden && (
            <Image
              src="/assets/images/header/nav-menu-mobile.png"
              alt="nav-mobile"
              width={20}
              height={20}
              onClick={() => setIsMobileMenuHidden((prevIsOpen) => !prevIsOpen)}
            />
          )}

          {!isMobileMenuHidden && (
            <Image
              src="/assets/images/header/close.png"
              alt="close"
              width={20}
              height={20}
              onClick={() => setIsMobileMenuHidden((prevIsOpen) => !prevIsOpen)}
            />
          )}
        </div>
        <div
          className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto whitespace-nowrap ${
            isMobileMenuHidden ? "hidden" : ""
          }`}
        >
          <div className="lg:flex lg:items-center lg:justify-start lg:w-full">
            {routes?.map((route: any, index: any) => (
              <div
                key={route.id}
                className={`flex flex-col lg:flex-col-reverse cursor-pointer relative group ${
                  index === 0 || index === 1 ? "md:ml-10" : "mr-10"
                } ${index === 2 ? "ml-auto" : ""}`}
              >
                <div className="relative">
                  {selectedIndex === index && (
                    <div className="md:block hidden traingle md:left-[22%] left-[84%] border-l-[25px] border-r-[25px] border-t-[15px] -top-[130%]"></div>
                  )}
                  <div className="flex items-baseline lg:items-center">
                    <a
                      onClick={() => {
                        setSelectedIndex(index);
                        toggleMenu(route.id);
                      }}
                      className="hover:text-black hover:border-b-[0] block mt-4 lg:inline-block lg:mt-0 text-white mr-4 font-avHeavy text-[18px]"
                    >
                      {route?.title}
                    </a>
                    {route?.subLinks && route?.subLinks.length > 0 && (
                      <Image
                        src="/assets/images/header/arrow-down.png"
                        alt="Dropdown Arrow"
                        width={12}
                        height={15}
                        className="-ml-[5px] group-hover:filter group-hover:brightness-0"
                        onClick={(e) => {
                          e.preventDefault();
                          if (route?.subLinks.length > 0) {
                            toggleMenu(route.id);
                          } else {
                            window.location.href = route.link;
                          }
                        }}
                      />
                    )}
                  </div>

                  {/* Submenu */}
                  {route?.subLinks && route?.subLinks.length > 0 && (
                    <ul
                      ref={dropdownRef}
                      onClick={closeMenu}
                      className={`${
                        openMenuId === route.id ? "flex" : "hidden"
                      } flex-col z-[100] mt-[5px] lg:mt-0 min-w-[160px] absolute top-[60px] bg-white border-[1px] border-[#E6E6E6] font-avHeavy text-[19px]`}
                    >
                      <Image
                        src="/assets/images/header/menu-arrow.svg"
                        className="self-center -mt-[1px]"
                        alt="Dropdown Arrow"
                        width={250}
                        height={35}
                      />
                      {route.subLinks.map((subroute: any, subIndex: any) => (
                        <Link
                          className="max-w-max text-black cursor-pointer hover:border-b-[4px] hover:text-red py-2 px-4 block whitespace-no-wrap h-[40px]"
                          key={subIndex}
                          href={subroute?.link || ""}
                        >
                          <span>{subroute?.text}</span>
                        </Link>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="bg-black text-white w-[260px] h-[45px] rounded-lg p-[10px] text-[16px] font-black mr-[15px] mt-[20px] md:mt-0">
            <Link href={storeLink} target="_blank" rel="noopener noreferrer">
              <div className="flex justify-around cursor-pointer">
                <div className="tracking-widest pt-[2px] text-white">
                  {" "}
                  GET THE APP
                </div>
                <div className="-mt-[3px]">
                  <Image
                    src="/assets/images/header/apple-store-icon.png"
                    alt="apple"
                    width={20}
                    height={20}
                  />
                </div>
                <div>
                  <Image
                    src="/assets/images/header/google-store-icon.png"
                    alt="google"
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}
